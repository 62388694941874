export const LINKS = [
  {
    name: 'Terms & Conditions',
    link: '#',
  },
  {
    name: 'Privacy Policy',
    link: '#',
  },
]
